<script>

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import circuitMixin from "@mixins/circuitMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import circuitService from "@services/circuitService";
import bilanMaterielService from "@services/bilanMaterielService";

export default {
  components: {AkFormView, AkDropdown},
  mixins: [randomRef, utilsMixin, circuitMixin, roleMixin],
  metaInfo() {
    return {
      title: "bilan_materiel.synthese",
    }
  },
  data() {
    return {
      year: 0,
      yearList: [],
      circuitList: [],
      rawData: null,
      map: [],
      muleMap: [],
      dbFields: ['truckType', 'brand', 'location', 'with1', 'without1', 'with2', 'without2', 'with3', 'without3', 'with4', 'without4', 'with5', 'without5', 'repairNumber'],
      isExportDownloading: false,
    }
  },
  mounted() {
    this.loading = true;
    this.getRef().showTotalLoader();
    
    let p1 = circuitService.findAllCurrent();
    p1.then(data => this.circuitList = data);
    
    let p2 = bilanMaterielService.findSyntheseByCurrentYear();
    p2.then(data => this.rawData = data);
  
    let p3 = bilanMaterielService.findAllYears();
    p3.then(data => {
      this.yearList.push({value: 0, label: this.$t('current_year')});
      data.forEach(year => this.yearList.push({value: year, label: year + ' - ' + (year + 1)}))
    });
  
  
    Promise.all([p1, p2, p3]).then(()=>this.afterPromise());
  },
  methods: {
    initData() {
      this.map = [];
      this.muleMap = [];
      this.rawData.bilanMaterielDtoList.forEach(el => {
       this.map[el.circuitId] = el;
      });
      this.muleMap = this.rawData.bilanMaterielMuleDtoList;
      this.initCircuitListForMaterielTable(this.circuitList, this.muleMap);
    },
    afterPromise() {
      this.loading = false;
      this.getRef().hideLoader();
      this.initData();
    },
    countForFieldTruck(circuit, field) {
      if (!this.map[circuit.id]) return '';
      return this.countForField(field, this.map[circuit.id][field]);
    },
    countForFieldMule(circuit, field) {
      if (!circuit) return '';
      return this.countForField(field, circuit[field]);
    },
    countForField(field, count) {
      if (field === 'location') {
        count = count ? this.$t('yes') : this.$t('no');
        return count;
      }
      if ((field === 'truckType' || field === 'brand') && !count) return this.$t('undefined');
      if (count) return count;
      if (field.includes('with')) return count;
      return !count ? 0 : count;
    },
    countTotalField(circuit, field) {
      let circuitTotal = this.circuitList.filter(c => c.agerId === circuit.agerIndex);
      let circuitTotalMule = circuitTotal.filter(c => c.mule);
      let circuitTotalTrucks = [];
      circuitTotal.forEach(c => {
        if (!c.mule && this.map[c.id]) circuitTotalTrucks.push(this.map[c.id])
      });
      if (field === 'truckType') return '';
      if (field === 'brand') return '';
      if (field === 'location') return circuitTotalMule.filter(c => c.location).length + circuitTotalTrucks.filter(c => c.location).length;
      return this.countTotal(field, circuitTotalTrucks, circuitTotalMule);
    },
    countTotal(field, circuitTotalTrucks, circuitTotalMule) {
      let total = 0;
      circuitTotalMule.forEach(c => total += c[field]);
      circuitTotalTrucks.forEach(c => total += c[field]);
      return total;
    },
    totalAger(circuit) {
      let circuitTotal = this.circuitList.filter(c => c.agerId === circuit.agerIndex);
      return circuitTotal.length;
    },
    reload(year) {
      this.loading = true;
      this.getRef().showTotalLoader();
      let p1;
      let p2;
      if (year !== 0) {
        p1 = circuitService.findAllByYear(year);
        p1.then(data => this.circuitList = data);
      
        p2 = bilanMaterielService.findSyntheseByYear(year);
        p2.then(data => this.rawData = data);
      } else {
        p1 = circuitService.findAllCurrent();
        p1.then(data => this.circuitList = data);
      
        p2 = bilanMaterielService.findSyntheseByCurrentYear();
        p2.then(data => this.rawData = data);
      }
    
      Promise.all([p1, p2]).then(()=>this.afterPromise());
    },
		download() {
      this.isExportDownloading = true;
			let p = bilanMaterielService.download(this.year);
			let promises = [p];
			p.catch(e=>{
				this.getRef().hideLoader();
				this.getRef().error(this.$t("error_download_synthese"));
			});
			Promise.all(promises).then(() => {
        this.isExportDownloading = false;
      });
		},
    getBilanMaterialData(id, mule = false) {
      if (!mule) {
        if (this.map[id]) return this.map[id];
        return {
          problemMaterielDTOList: []
        };
      } else {
        let foundMule = this.muleMap.find(mule => mule.mule === id);
        if (foundMule) return foundMule;
        return {
          problemMaterielDTOList: []
        };
      }
    },
    goToBilan(id) {
      this.$router.push({name: 'bilanMaterielDetails', params: {id: id}});
    }
  },
  watch: {
    year(newType) {
      this.reload(newType);
    },
    displayArchiveData(){
      return this.year !== 0;
    }
  },
}
</script>

<template v-if=!roleLoading>
  <AkFormView
    :ref="ref"
    :title="$t('bilan_materiel.synthese')"
    :displayGoBack=false>
    <template v-slot:action>
      <button @click="download()" style="margin-right: 10px;" class="btn btn-inverse-primary" :disabled="isExportDownloading">
        <i class="fe fe-download pr-1" v-if="!isExportDownloading"/>
        <i class="pi pi-spin pi-spinner mr-1" v-if="isExportDownloading"></i>
        {{ $t('download_excel') }}
      </button>
      <RouterLink v-if=canEditEquipment
        :to="{ name: 'bilanMaterielList' }"
        class="btn btn-inverse-primary">
        <i class="fe fe-settings pr-1"/>
        {{ $t('manage') }}
      </RouterLink>
    </template>
    <template v-slot:form>
      <div class="row">
        <AkDropdown :label="$t('year')"
                    v-if=canViewEquipmentArchived
                    v-model="year"
                    :options=this.yearList
                    class-name="col-md-2"/>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive forecast-table">
                <table style="display: block; overflow-x: auto;">
                  <thead>
                  <tr>
                    <th scope="col" rowspan="3">{{ $t('ager_label') }}</th>
                    <th scope="col" rowspan="3">{{ $t('uer_label') }}</th>
                    <th scope="col" rowspan="3">{{ $t('cei_label') }}</th>
                    <th scope="col" rowspan="3">{{ $t('bilan_materiel.circuit') }}</th>
                    <th scope="col" rowspan="3">{{ $t('bilan_materiel.sections') }}</th>
                    <th scope="col" rowspan="3">{{ $t('bilan_materiel.axes') }}</th>
                    <th scope="col" rowspan="3">{{ $t('bilan_materiel.truck_type') }}</th>
                    <th scope="col" rowspan="3">{{ $t('bilan_materiel.brand') }}</th>
                    <th scope="col" rowspan="3">{{ $t('bilan_materiel.rent') }}</th>
                    <th scope="col" colspan="10">{{ $t('bilan_materiel.salt_unit_brine') }}</th>
                    <th scope="col" rowspan="3">{{ $t('bilan_materiel.nb_repair_materiel_vh') }}</th>
                    <th scope="col" rowspan="5">{{ $t('bilan_materiel.problem_met_and_suggestions') }}</th>
                  </tr>
                  <tr>
                    <th scope="col" colspan="2">{{ $t('bilan_materiel.v3m3') }}</th>
                    <th scope="col" colspan="2">{{ $t('bilan_materiel.v4a45m3') }}</th>
                    <th scope="col" colspan="2">{{ $t('bilan_materiel.v5m3') }}</th>
                    <th scope="col" colspan="2">{{ $t('bilan_materiel.v6m3') }}</th>
                    <th scope="col" colspan="2">{{ $t('bilan_materiel.v7a8m3') }}</th>
                  </tr>
                  <tr>
                    <template v-for="n in 5" :key=n>
                      <th scope="col">{{ $t('bilan_materiel.with') }}</th>
                      <th scope="col">{{ $t('bilan_materiel.without') }}</th>
                    </template>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="circuit in circuitList" :key="circuit.id">
                    <template v-if="circuit.mule">
                      <tr>
                        <td v-if="circuit.uerSize" colspan="2" :rowspan="circuit.uerSize-1">{{ $t('bilan_materiel.ager_mule', [circuit.agerLabel]) }}</td>
                        <td>{{ circuit.mule }}</td>
                        <td colspan="2"></td>
                        <td v-for="field in dbFields" :key="field">{{ countForFieldMule(circuit, field) }}</td>
                        <td>
                          <div v-for="problem in getBilanMaterialData(circuit.mule, true).problemMaterielDTOList" :key="problem" class="problem">
                            <p>{{ problem.problem }}</p>
                            <div class="btn btn-xs btn-inverse-primary" @click="goToBilan(problem.bilanMaterielId)">
                              <i class="fe fe-eye"/>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <template v-else-if="circuit.total">
                      <tr style="background-color: #F8F8F8;">
                        <th scope="row" colspan="3">{{ $t('bilan_materiel.total', [circuit.agerLabel]) }}</th>
                        <th scope="row" colspan="3">{{ totalAger(circuit) }}</th>
                        <th scope="row" v-for="field in dbFields" :key="field">{{ countTotalField(circuit, field) }}</th>
                        <th scope="row" colspan="3">{{ countTotalField(circuit, "problemComment") }}</th>
                      </tr>
                    </template>
                    <tr v-else>
                      <td v-if=circuit.agerSize :rowspan=circuit.agerSize>{{ circuit.agerLabel }}</td>
                      <td v-if=circuit.uerSize :rowspan=circuit.uerSize>{{ circuit.uerLabel }}</td>
                      <td v-if=circuit.ceiSize :rowspan=circuit.ceiSize>{{ circuit.ceiLabel }}</td>
                      <td>{{ circuit.number }}</td>
                      <td>{{ circuit.section }}</td>
                      <td>{{ circuit.axis }}</td>
                      <td v-for="field in dbFields" :key="field">{{ countForFieldTruck(circuit, field) }}</td>
                      <td>
                        <div v-for="problem in getBilanMaterialData(circuit.id).problemMaterielDTOList" :key="problem" class="problem">
                          <p>{{ problem.problem }}</p>
                          <div class="btn btn-xs btn-inverse-primary" @click="goToBilan(problem.bilanMaterielId)">
                            <i class="fe fe-eye"/>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AkFormView>
</template>
<style scoped>
.problem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.problem > p {
  color: #495057;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space:nowrap;
  overflow: hidden;
}

.problem:not(:first-child) {
  padding-top: 10px;
}
</style>