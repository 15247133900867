import Api from "@/services/api";
import {removeEmptyParams, downloadData} from "@utils";

export default {
  findSyntheseByYear(year) {
    return Api().get("/bilanmateriel/synthese/" + year).then(res => {
      return res.data;
    });
  },
  findSyntheseByCurrentYear() {
    return Api().get("/bilanmateriel/synthese").then(res => {
      return res.data;
    });
  },
  findAll(filter) {
    return Api().get("/bilanmateriels", {params: removeEmptyParams(filter)}).then(res => {
      res.data.forEach(e => this.formatRes(e));
      return res.data;
    });
  },
  findAllCurrent(filter) {
    return Api().get("/bilanmateriels/current", {params: removeEmptyParams(filter)}).then(res => {
      res.data.forEach(e => this.formatRes(e));
      return res.data;
    });
  },
  findAllByYear(year) {
    return Api().get("/bilanmateriels/year/" + year).then(res => {
      res.data.forEach(e => this.formatRes(e));
      return res.data;
    });
  },
  findAllYears() {
    return Api().get("/bilanmateriels/years/").then(res => res.data);
  },
  findById(id) {
    return Api().get("/bilanmateriel/" + id).then(res => this.formatRes(res.data));
  },
  findByCircuitId(circuitId) {
    return Api().get("/bilanmateriel/circuit/" + circuitId).then(res => this.formatRes(res.data));
  },
  findByMule(mule) {
    return Api().get("/bilanmateriel/mule/" + mule).then(res => this.formatRes(res.data));
  },
  findCurrentYear() {
    return Api().get("/bilanmateriel/currentyear").then(res => res.data);
  },
  create(bilanMateriel) {
    return Api().post("/bilanmateriel", bilanMateriel).then(res => this.formatRes(res.data));
  },
  update(bilanMateriel) {
    return Api().put("/bilanmateriel/" + bilanMateriel.id, bilanMateriel).then(res => this.formatRes(res.data));
  },
  delete(bilanMateriel) {
    return Api().delete("/bilanmateriel/" + bilanMateriel.id).then(() => bilanMateriel);
  },
  formatRes(e) {
    return e;
  },
  download(year) {
      return Api().get("/bilanmateriel/download/" + year, {responseType: "blob"}).then(res => downloadData(res));
  }
}